import React from 'react';
import { Link } from 'gatsby';
import Slide from 'react-reveal/Slide';

import Layout from 'src/components/Layout';
import TestImage1 from 'src/images/test-image-1.jpg';
import TestImage2 from 'src/images/test-image-2.jpg';
import TestImage3 from 'src/images/test-image-3.jpg';
import FarmProduct1 from 'src/images/farm-product-1.jpg';
import FarmProduct2 from 'src/images/farm-product-2.jpg';
import FarmImage from 'src/images/simplicity-farms.jpg';
import StripeImage1 from 'src/images/logo-stripe.png';
import GastbyIcon from 'src/images/gatsby-icon.png';
import TailwindIcon from 'src/images/tailwind.png';
import WarelmaLogo from 'src/images/warelma/logo.png';
import SimpleMap from 'src/components/SimpleMap';
import ContactBlock from "src/components/ContactBlock";


import SEO from 'src/components/SEO';

const IndexPage = () => (
  <Layout>
    <SEO title="Over ons" />


  </Layout>
);

export default IndexPage;
